<template>
  <layout width="540">
    <template slot="header">Change access</template>
    <v-form ref="form">
      <edit-rights-group-fields
        v-model="state"
        :rights-editor="rightsEditor"
        :rights-user="rightsUser"
        :rights-reader="rightsReader"
      />
    </v-form>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click="$emit('close')">Cancel</v-btn>
      <v-btn
        color="primary"
        text
        :loading="loading"
        :disabled="loading"
        @click="saveHandler"
      >
        save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import EditRightsGroupFields from '@/modules/access/ui/EditRightsGroupFields.vue';
import { onMounted, ref } from '@vue/composition-api';
import { useAccessRights } from '@/modules/access/compositions/access-rights';

export default {
  name: 'EditRightsGroup',
  components: { Layout, EditRightsGroupFields },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    many: {
      type: Boolean,
      default: false
    },
    objectIds: {
      type: Array,
      default: () => []
    },
    onConfirm: {
      type: Function,
      default: null
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);

    let state = ref({
      editorgroup: '',
      usergroup: '',
      readergroup: ''
    });

    const {
      load,
      rightsEditor,
      rightsUser,
      rightsReader,
      save,
      saveMany,
      fetchAccessRightsForObject
    } = useAccessRights();

    onMounted(async () => {
      load();
      if (!props.many) {
        state.value = await fetchAccessRightsForObject(props.objectId);
      } else {
        state.value = await fetchAccessRightsForObject(props.objectIds[0]);
      }
    });

    const saveHandler = async () => {
      try {
        loading.value = true;
        if (!props.many) {
          await save(props.objectId, state.value);
        } else {
          await saveMany(props.objectIds, state.value);
        }
        emit('close');
        props.onConfirm && props.onConfirm();
      } finally {
        loading.value = false;
      }
    };

    return {
      rightsEditor,
      rightsUser,
      rightsReader,
      saveHandler,
      loading,
      state
    };
  }
};
</script>
